import { request } from "./request";

export default function updateBlog(objectId) {
    const url = process.env.NODE_ENV === 'production' ? '/api/blog/detail/' : '/blog/detail/';  
    return request({
        url: url + objectId + '/',
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'X-LC-Id': 'r57JSq9yQU4WHkBh3WlJktM0-gzGzoHsz',
            'X-LC-Key': '6B1HmkLavgUf3SKXAGziVUhK'
        }
    })
}